<template>
  <div id="footer" class="mt-3">
    <div class="container-lg">
      <footer class="row align-items-center">
        <div class="col-12 py-4 text-center">
          <p
            class="text-muted small m-0 fw-light"
          >
            © 2022 Hakcipta Terpelihara Tabung Haji
          </p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/color.scss";

.alignment-text {
  &.start {
    text-align: left;

    @media (max-width: 420px) {
      text-align: center;
    }
  }
  &.end {
    text-align: right;
    color: rgb(181, 182, 198) !important;

    @media (max-width: 420px) {
      text-align: center;
    }
  }
}

#footer {
  background-color: $white;

  footer {
    small {
      color: $gray-light;
    }
    a {
      color: $gray-reqular;
    }
  }
}
</style>