<template>
    <div id="admin-app">
        <top-nav-comp />
        <!-- Initiate Routing Child components -->
        <router-view />
        <footer-comp />
    </div>
</template>

<script>
import TopNavComp from './../../containers/admin/TopNav.vue'
import FooterComp from './../../containers/Footer.vue'
export default {
    components: {
        TopNavComp,
        FooterComp
    }
}
</script>

<style lang="scss">
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

#admin-app {
    .container-header {
        
        .item-right {
            width: 450px;

            input[type=text] {
                display: inline;
                max-width: 250px;
                margin-left: 5px;
                padding: 10px 15px;
                border-radius: 5px;
            }
        }
    }
    
    .header-full {
        // height: 100px;
        padding: 25px;
        background-color: $gray-light-04;
    }

    .body-full {
        background-color: $gray-dark-05;

        .container-box {
            background-color: $white;
            padding: 30px 25px;
            border-radius: 5px;
            overflow: auto;
        }
    }

    .breadcrumb {

        .breadcrumb-item {

            a {
                color: unset;
                text-decoration: unset;
            }
        }
    }

    .break-title {
        width: 100%;
        height: 15px;
        border-bottom: 1px solid $gray-light-02;
        text-align: center;
        margin-bottom: 40px;

        span {
            font-size: 18px;
            // background-color: $white;
            padding: 0 10px;
        }
    }

    .card-lists {

        .card {
            height: 210px;
            // width: 254px;
        }
        &:hover {
            cursor: pointer;
        }
    }

    .modal-dialog {
        .modal-content {
            padding: 12px;

            .modal-footer {
                border-top: unset;

                .btn {
                    padding: 10px 50px 10px 50px !important;
                    width: 220px;

                    &.btn-full {
                        width: 100%;
                    }
                }
            }
        }
    }

    .table {

        thead {
            background-color: $gray-dark-05;

            th {
                padding: 15px 15px;
                color: $gray-reqular;
                font-weight: unset;
                font-size: 16px;
            }
        }

        tbody {

            td {
                padding: 15px 15px;
                color: $gray-dark;
                font-weight: unset;
                font-size: 16px;

                a {
                    color: unset;
                    text-decoration: unset;
                }
            }
        }
    }

    .file-list {
        border: 0;
        padding: 8px;
        .file-icon {
            font-size: 24px;
        }
        span {
            font-size: 16px;
            font-style: normal;
            padding-left: 8px;
        }
    }

    .file-upload-box {
        height: 150px;
        border-radius: 7px;
        border: 1px solid $gray-light-02;

        .file-upload-box-content {
            cursor: pointer;
            text-align: center;
            padding: 45px 0px;

            .title {
                color: $green;
            }
        }
    }
}
</style>