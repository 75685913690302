<template>
  <div class="top-nav bg-white">
    <div class="container">
      <nav class="navbar navbar navbar-expand-lg navbar-light pt-3 pb-3">
        <div class="container-lg">
          <router-link to="/" class="navbar-brand">
            <img src="./../../assets/logo_1.svg" />
          </router-link>
          <button
            class="navbar-toggler ms-auto border-0 order-1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <ul class="mb-0 p-0 order-2 order-lg-3 d-flex">
            <!-- <li class="nav-item d-block">
              <div v-if="currentUser">
                <button class="btn btn-white px-0" type="button">
                  <img src="../../assets/img/icon_bell.png" class="avatar"/>
                </button>
              </div>
            </li> -->
            <li class="nav-item d-block">
              <div v-if="currentUser">
                <div class="dropdown">
                  <button
                    class="btn btn-white dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../assets/img/Sample_User_Icon.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <li>
                      <a class="dropdown-item">{{
                        currentUser && currentUser.email
                      }}</a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <!-- <li>
                      <router-link to="/" class="dropdown-item">
                        Utama
                      </router-link>
                    </li>
                    <li class="dropdown-divider"></li> -->
                    <li>
                      <router-link
                        to="/user/profile/details"
                        class="dropdown-item"
                      >
                        Akaun Saya
                      </router-link>
                    </li>
                    <template
                      v-if="
                        currentUser &&
                        currentUser.role &&
                        currentUser.role.name == 'Administrator'
                      "
                    >
                      <li class="dropdown-divider"></li>
                      <li>
                        <router-link
                          to="/admin/dashboard"
                          class="dropdown-item"
                        >
                          Admin
                        </router-link>
                      </li>
                      <li class="dropdown-divider"></li>
                      <li>
                        <router-link
                          to="/admin/settings"
                          class="dropdown-item"
                        >
                          Tetapan
                        </router-link>
                      </li>
                    </template>
                    <li class="dropdown-divider"></li>
                    <li>
                      <div class="dropdown-item" @click="authLogout()">
                        Daftar Keluar
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <div
            class="collapse navbar-collapse order-3 order-lg-2"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <template v-for="(tab, i) in tabs">
                <li
                  class="nav-item me-3"
                  :key="i"
                  v-if="tab.roles.includes(currentUser.admin_type)"
                >
                  <router-link
                    class="nav-link"
                    :class="{ active: isActiveNav(tab.path) }"
                    aria-current="page"
                    :to="tab.path"
                    >{{ tab.name }}</router-link
                  >
                </li>
              </template>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      tabs: [
        {
          name: "Dashboard",
          path: "/admin/dashboard",
          roles: ["superadmin", "admin_hq", "admin_management", "admin_state"],
        },
        {
          name: "Halaman",
          path: "/admin/pages",
          roles: ["admin_hq"],
        },
        {
          name: "Kursus",
          path: "/admin/courses",
          roles: ["admin_hq"],
        },
        {
          name: "Rujukan",
          path: "/admin/media",
          roles: ["admin_hq"],
        },
        {
          name: "Bank Soalan",
          path: "/admin/questions",
          roles: ["admin_hq"],
        },
        {
          name: "Soal Ibadat",
          path: "/admin/past-questions",
          roles: ["admin_hq"],
        },
        {
          name: "Penilaian",
          path: "/admin/survey-questions",
          roles: ["admin_hq"],
        },
        {
          name: "Pengguna",
          path: "/admin/users",
          roles: ["admin_hq"],
        },
        {
          name: "Fail",
          path: "/admin/files",
          roles: ["admin_hq"],
        },
        {
          name: "Senarai Admin",
          path: "/admin/list-admin",
          roles: ["superadmin"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["authLogout"]),
    isActiveNav(name) {
      return this.$route.path.includes(name);
    },
    gotoBtn(_path) {
      this.$router.push({ path: _path });
    },
  },
  mounted() {
    const tabCanAccess = this.tabs.find((tab) => this.$route.path == tab.path);
    if (
      !tabCanAccess ||
      !tabCanAccess.roles.includes(this.currentUser.admin_type)
    ) {
      const tabsAllow = this.tabs.find((tab) =>
        tab.roles.includes(this.currentUser.admin_type)
      );
      if (tabsAllow) {
        this.$router.replace(tabsAllow.path);
      }
    }

    // const tabsAllow = this.tabs.find()
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";

.top-nav {
  border-bottom: 2px solid $gray-light-02;

  .container {
    nav {
      a {
        color: $gray-reqular !important;

        &.nav-link.active {
          // color: $green!important;
          color: $black !important;
          border-radius: 5px;
          font-weight: bold;
          // background: $green-light;
          // padding: 10px 15px;
        }
      }
    }

    .dropdown-item {
      &:active {
        background-color: white;
      }
    }
  }
}
</style>